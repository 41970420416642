const ACCOUNT_TYPE = {
  ORGANISATION: "organisation",
  PERSONAL: "personal",
  ENTERPRISE: "enterprise",
};

const STATUS = {
  DOWNLOADED: "downloaded",
  PASS_CREATED: "pass_created",
};

const ROLE = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  EMPLOYEE: "employee",
};

export { ACCOUNT_TYPE, STATUS, ROLE };
