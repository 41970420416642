import { Environment } from "enums";

export const PLANS = {
  STARTER: "Professional",
  BUSINESS: "Business",
  ENTERPRISE: "Enterprise",
  CUSTOM: "custom",
};

export const SUBSCRIPTION_PERIODS = {
  MONTHLY: "monthly",
  ANNUALLY: "annually",
};

const Starter = {
  heading: "Professional",
  services: [
    "Up to 20 Card",
    "1 Design Template",
    "1 Admin (Control cardholder details)",
    "24/7 Support",
  ],
  plan: PLANS.STARTER,
  cards: 20,
  trees: 1,
  designs: 1,
  admins: 1,
  personalBranding: false,
  bulkUpload: false,
  cardPermission: false,
  onBoarding: false,
  support: false,
  customerServiceManager: false,
  whiteLabel: false,
  hrIntegration: false,
  // amount: 1440,
  // VAT: 216,
  // afterDiscount: 993.6,
};
const Basic = {
  heading: "Business",
  previousPlan: "Everything included in Starter, plus:",
  services: [
    "Up to 100 Card",
    "3 Design Template",
    "1 Admin (Control cardholder details)",
    "Bulk Cardholder Upload",
    "Customer Success Manager",
    "Live Onbaording Training",
    "24/7 Support",
  ],
  plan: PLANS.BASIC,
  cards: 100,
  trees: 20,
  designs: "any",
  admins: 2,
  personalBranding: true,
  bulkUpload: true,
  cardPermission: false,
  onBoarding: false,
  support: false,
  customerServiceManager: false,
  whiteLabel: false,
  hrIntegration: false,
  // amount: 4800,
  // VAT: 720,
  // afterDiscount: 3312,
};
const Business = {
  heading: "Business",
  previousPlan: "Everything included in Basic, plus:",
  services: [
    "Up to 100 Card",
    "3 Design Template",
    "1 Admin (Control cardholder details)",
    "Bulk Cardholder Upload",
    "Customer Success Manager",
    "Live Onbaording Training",
    "24/7 Support",
  ],
  plan: PLANS.BUSINESS,
  cards: 500,
  trees: 50,
  designs: "any",
  admins: 5,
  personalBranding: true,
  bulkUpload: true,
  cardPermission: true,
  onBoarding: true,
  support: true,
  customerServiceManager: true,
  whiteLabel: false,
  hrIntegration: false,
  // amount: 23400,
  // VAT: 3510,
};
const Enterprise = {
  heading: "Enterprise",
  previousPlan: "Everything included in Business, plus:",
  services: [
    "Custom number of Cards",
    "Unlimited Design Templates",
    "Unlimited Admins (Control cardholder details)",
    "Bulk Cardholder Upload",
    "Cardholder Permissions",
    "Live Onbaording Training",
    "White-labelled Login & Interface",
    "Dedicated Customer",
    "Success Manager",
    "Standard SSO & Directory Sync Integrations",
    "Priority Technical Support",
    "24/7 Support",
  ],
  plan: PLANS.ENTERPRISE,
  cards: "any",
  trees: "any",
  designs: "any",
  admins: "any",
  personalBranding: true,
  bulkUpload: true,
  cardPermission: true,
  onBoarding: true,
  support: true,
  customerServiceManager: true,
  whiteLabel: true,
  hrIntegration: true,
  // amount: 99912,
  // VAT: 13032,
};

export const PLAN_DETAILS = (period) => {
  if (period === SUBSCRIPTION_PERIODS.MONTHLY) {
    const type = "month";
    return {
      [PLANS.STARTER]: {
        ...Starter,
        type,
        subscriptionPeriod: period,
        amount: process.env.REACT_APP_ENV === Environment.STAGING ? 1 : 100,
      },
      [PLANS.BASIC]: {
        ...Basic,
        type,
        subscriptionPeriod: period,
        amount: process.env.REACT_APP_ENV === Environment.STAGING ? 1 : 275,
      },
      [PLANS.BUSINESS]: {
        ...Business,
        type,
        subscriptionPeriod: period,
        amount: process.env.REACT_APP_ENV === Environment.STAGING ? 1 : 2250,
      },
      [PLANS.ENTERPRISE]: {
        ...Enterprise,
        type,
        subscriptionPeriod: period,
      },
    };
  } else {
    const type = "year";
    return {
      [PLANS.STARTER]: {
        ...Starter,
        type,
        subscriptionPeriod: period,
        freeMonths: 2,
        amount: process.env.REACT_APP_ENV === Environment.STAGING ? 1 : 1200,
      },
      [PLANS.BASIC]: {
        ...Basic,
        type,
        subscriptionPeriod: period,
        freeMonths: 2,
        amount: process.env.REACT_APP_ENV === Environment.STAGING ? 1 : 3300,
      },
      [PLANS.BUSINESS]: {
        ...Business,
        type,
        subscriptionPeriod: period,
        freeMonths: 2,
        amount: process.env.REACT_APP_ENV === Environment.STAGING ? 1 : 2700,
      },
      [PLANS.ENTERPRISE]: {
        ...Enterprise,
        type,
        subscriptionPeriod: period,
      },
    };
  }
};
