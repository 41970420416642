// global.js
window.globalState = {
  id: "",
  token: "",
  voucherId: "",

  setData(userId, token) {
    this.id = userId;
    this.token = token;
  },

  setVoucherId(newId) {
    this.voucherId = newId;
  },
};
