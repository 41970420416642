import BrandImage from "assets/images/Official.png";
import axios from "axios";
import Loader from "components/loader";
import env from "config";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { osName } from "react-device-detect";
import Api from "utils/api";

function GenerateWalletPass() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState();
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState("");
  const [passDownloaded, setPassDownloaded] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const downloadPass = async () => {
    const startTime = new Date();
    const url = `${env.API_URL}/v1/pass/apple?token=${token}`;

    try {
      const response = await axios({
        url: url,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);

          const currentTime = new Date();
          const elapsedTime = currentTime - startTime;
          const downloadSpeed = progressEvent.loaded / (elapsedTime / 1000);
          const totalSize = progressEvent.total;
          const remainingBytes = totalSize - progressEvent.loaded;
          const estimatedTimeRemaining = remainingBytes / downloadSpeed;

          setEstimatedTimeRemaining(estimatedTimeRemaining.toFixed(0));
        },
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.apple.pkpass",
      });
      const blobURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = `wallet_pass_${Math.random()
        .toString(36)
        .substring(2, 15)}.pkpass`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobURL);

      const file = new File([blob], link.download);
      await uploadFile(file);
      setPassDownloaded(true);
    } catch (error) {
      console.error("Error downloading the pass:", error);
    }
  };

  const uploadFile = async (file) => {
    setShowProgress(true);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const { response, error } = await Api("/design/upload", "post", {
        payload: formData,
      });

      if (response) {
        setShowProgress(false);
        const redirectUrl = `https://walletpass.io?u=${response.data.data}`;
        console.log("RedirectUrl--->", redirectUrl);
        setUrl(redirectUrl);
        // setTimeout(() => {
        //   window.location.replace(redirectUrl);
        // }, 1000);
      }
    } catch (error) {
      setShowProgress(false);
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    downloadPass();
  }, []);

  const handleClose = () => {
    window.close();
  };

  const handleImport = () => {
    setTimeout(() => {
      window.location.replace(url);
    }, 1000);
  };

  return (
    <div className="tw-px-4 tw-flex tw-justify-center tw-items-center tw-w-full tw-h-[100vh]">
      <div className="tw-flex tw-flex-col tw-w-[400px] tw-justify-center tw-max-w-full sm:tw-mr-5 tw-mb-8 sm:tw-mb-0 tw-mt-3 sm:tw-mt-0 ">
        <div className="tw-mb-8 tw-w-40 tw-mx-auto">
          <img src={BrandImage} alt="Brand" />
        </div>
        <div className="tw-w-full tw-relative">
          <div className="tw-bg-[#fad745] tw-w-full tw-h-2 tw-rounded-sm tw-absolute"></div>
          <div
            className="tw-bg-mint tw-h-2 tw-rounded-sm tw-absolute"
            style={{ width: progress + "%" }}
          ></div>
        </div>
        <div className="tw-w-full tw-mt-6 tw-text-center">
          {showProgress ? (
            <Loader size={6} />
          ) : (
            <p className="tw-text-electricGreen tw-text-sm">
              Your digital business card is going to be ready in (
              {estimatedTimeRemaining} seconds)
            </p>
          )}
        </div>
        {passDownloaded && osName.toLocaleLowerCase() === "android" ? (
          <div className="tw-w-full tw-mt-6 tw-text-center">
            <button
              className="tw-bg-electricGreen tw-text-white tw-px-4 tw-py-2 tw-rounded"
              onClick={handleImport}
            >
              Import Into WalletPasses
            </button>
          </div>
        ) : (
          passDownloaded && (
            <div className="tw-w-full tw-mt-6 tw-text-center">
              <button
                className="tw-bg-electricGreen tw-text-white tw-px-4 tw-py-2 tw-rounded"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default GenerateWalletPass;
